@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {
  @font-face {
    font-family: 'Harabara';
    src: url(assets/font/HarabaraBold.otf) format('opentype');
    font-stretch: normal;
    font-weight: bold;
    font-style: normal;
  }
}

/* ---------- Shared Admin Components styles ---------- */

.iconBtnContainer {
  @apply h-9 w-9 rounded relative flex flex-col justify-around p-1;
}

.iconBtnSmall {
  @apply h-6 w-6 p-0 !important;
}

.dropdownMenuContainer {
  @apply hidden absolute top-full right-0 group-hover:flex p-1;
}

.dropdownItems {
  @apply flex flex-col w-48 py-2 z-50 rounded shadow bg-white border;
}

/* ---------- Admin card ---------- */

.adminContentCard {
  @apply p-4 rounded bg-white m-4 shadow flex flex-col space-y-2;
}

.adminContentCardHeader {
  @apply -mx-4 -mt-4 px-4 py-2 border-b flex space-x-2 items-center;
}

.adminContentCardHeader h1 {
  @apply flex-grow text-slate-700 text-xl;
}

.adminContentCardBody {
  @apply flex flex-col p-4;
}

.adminContentCardBody h2 {
  @apply text-slate-700 text-lg font-semibold;
}

.adminContentCardBody .selected {
  @apply text-orange-500 border-orange-500 border-b;
}

.adminContentItem {
  @apply p-4 shadow bg-neutral-50 bg-opacity-70 border border-neutral-100;
}

/* ---------- Admin forms ---------- */

.adminFormGroup {
  @apply flex flex-col;
}

.adminFormGroup input, textarea, select {
  @apply rounded border-0 bg-slate-100 focus:ring-slate-200;
}

.adminFormGroup button {
  @apply flex rounded hover:shadow items-center;
}

.adminFormGroup .error {
  @apply bg-red-100 text-white focus:ring-red-200 !important;
}

.adminFormGroup label {
  @apply font-semibold;
}

/* ----------Adming general styles ---------- */

.adminContainer {
  @apply min-h-screen relative bg-stone-50 z-0 font-monserrat;
}

.adminContainer h1 {
  @apply text-2xl text-slate-600 p-2 font-semibold;
}

/* ---------- Web showcase styles ---------- */

.webShowcase {
  @apply flex flex-col pt-16 relative;
}

.webShowcase h1 {
  @apply text-5xl font-bold font-harabara;
}

.webShowcase h2 {
  @apply text-4xl font-semibold font-quicksand;
}

.webShowcase h3 {
  @apply text-2xl font-semibold font-quicksand;
}

.webShowcase h4 {
  @apply text-xl font-semibold font-quicksand;
}

.webShowcase input {
  @apply bg-lhv-light-dark rounded border-0 focus:ring-lhv-dark;
}

.actionButton {
  @apply text-lhv-dark-green bg-lhv-light border border-neutral-300 font-semibold
    hover:bg-lhv-electric-green hover:text-white transition-colors duration-300;
}

.actionButton:disabled {
  @apply bg-slate-100 text-slate-500 pointer-events-none;
}
